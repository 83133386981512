<template>
    <div>
      <div v-if="readonly">
        {{maskedNumber}}
      </div>
      <div class="" v-else>
        <VuePhoneNumberInput 
            v-model="phoneInput" 
            ref="phoneNumberInput" 
            default-country-code="US" 
            :preferred-countries="preferredCountries" 
            :border-radius="0" 
            :disabled="disabled"
            :name="field.slug"
            :data-vv-as="field.slug" 
            v-validate="validations"/>
      </div>
    </div>
</template>

<script>
    import VuePhoneNumberInput from 'vue-phone-number-input';
    import 'vue-phone-number-input/dist/vue-phone-number-input.css';

    export default {
        props: {
          value: [Object],
          readonly: {
            type: Boolean,
            default: false
          },
          disabled: {
            type: Boolean,
            default: false
          },
          field: {
            type: Object,
            required: true
          },
          validations: {
            type: String,
            default: 'required'
          }
        },
        inject: ['$validator'],
        components: {VuePhoneNumberInput},
        data: function () {
            return {
                type: 'mobile',
                countryCode: '+52',
                country: '',
                number: '',
                maskedNumber: '',
                preferredCountries: [
                    'US',
                    'CA',
                    'MX'
                ],
                phoneInput: null,
            }
        },
        mounted() {
          let phoneNumberInput = this.$refs.phoneNumberInput;
          if (this.value){
            this.maskedNumber = this.value.maskedNumber;
            this.phoneInput = this.value.maskedNumber;
            if (phoneNumberInput){
              phoneNumberInput.setLocale(this.value.country);
            }
          }
        },
        methods: {

        },
        watch: {
          phoneInput(){
            let phoneNumberInput = this.$refs.phoneNumberInput;
            let value = null;
            if (phoneNumberInput){
              value = {
                countryCode: '+' + phoneNumberInput.results.countryCallingCode,
                country: phoneNumberInput.results.countryCode,
                number: phoneNumberInput.results.nationalNumber,
                fullNumber: phoneNumberInput.results.e164,
                maskedNumber: phoneNumberInput.results.formatInternational
              }
              this.$emit('input', value);
            }
          }
        }

    }
</script>

<style scoped>

</style>