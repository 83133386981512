
<div>
  <div v-if="readonly">
    {{maskedNumber}}
  </div>
  <div class="" v-else>
    <VuePhoneNumberInput 
        v-model="phoneInput" 
        ref="phoneNumberInput" 
        default-country-code="US" 
        :preferred-countries="preferredCountries" 
        :border-radius="0" 
        :disabled="disabled"
        :name="field.slug"
        :data-vv-as="field.slug" 
        v-validate="validations"/>
  </div>
</div>
